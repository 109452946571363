import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Typography } from '@mui/material';
import { formatRut } from '../../helpers';

const EditingGrid = ({ title, user, getAttribute, onChangeInput, isRut, errorMsg }) => {
  const format = (value) => {
    if (isRut) return formatRut(String(value));
    return value;
  };
  const [state, setState] = useState(format(getAttribute(user) || ''));
  const onChange = (event) => {
    const newValue = event.target.value;
    if (isRut && newValue.length) {
      const regex = /[0-9kK]/g;
      if (!newValue.replaceAll('-', '').slice(-1).match(regex)) return;
    }
    onChangeInput(format(newValue));
    setState(format(newValue));
  };
  return (
    <Grid item xs={6} sm={6} md={4}>
      <Typography noWrap paragraph gutterBottom variant="h6" fontWeight={700}>
        {title}
      </Typography>
      <TextField
        variant="standard"
        value={state}
        disabled={!onChangeInput}
        error={!!errorMsg}
        helperText={errorMsg}
        onChange={onChange}
      />
    </Grid>
  );
};

EditingGrid.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  getAttribute: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func,
  isRut: PropTypes.bool,
  errorMsg: PropTypes.string,
};

EditingGrid.defaultProps = {
  onChangeInput: null,
  isRut: false,
  errorMsg: null,
};

export default EditingGrid;
