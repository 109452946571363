import React from 'react';
import { Avatar, Tooltip, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import CancelScheduleSend from '@mui/icons-material/CancelScheduleSend';
import ScheduleSend from '@mui/icons-material/ScheduleSend';
import Send from '@mui/icons-material/Send';

const StatusTrafficLight = ({ value }) => {
  const theme = useTheme();
  const { Icon, color, tooltipText } = {
    PENDING: { Icon: ScheduleSend, color: theme.palette.warning.main, tooltipText: 'Pendiente' },
    INITIATED: { Icon: Send, color: theme.palette.warning.main, tooltipText: 'En proceso' },
    COMPLETED: { Icon: Send, color: theme.palette.success.main, tooltipText: 'Envíado' },
    FAILED: { Icon: CancelScheduleSend, color: theme.palette.error.main, tooltipText: 'Envío fallido' },
    CANCELED: { Icon: CancelScheduleSend, color: theme.palette.error.main, tooltipText: 'Envío fallido' },

  }[value];
  return (
    <Tooltip title={tooltipText}>
      <Avatar
        sx={{
          height: 20,
          width: 20,
          background: color,
        }}
      >
        <Icon
          size="small"
          sx={{
            height: 12,
            width: 12,
          }}
        />
      </Avatar>
    </Tooltip>
  );
};

StatusTrafficLight.propTypes = {
  value: PropTypes.string.isRequired,
};

export default StatusTrafficLight;
