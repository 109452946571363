import * as React from 'react';
import TextField from '@mui/material/TextField';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { Grid } from '@mui/material';

const FingoDateTimePicker = (props) => (
  <Grid minWidth="100%">
    <DesktopDateTimePicker
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          inputProps={{ ...params.inputProps, 'data-hj-allow': '' }}
        />
      )}
      {...props}
    />
  </Grid>
);

export default FingoDateTimePicker;
